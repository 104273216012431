import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ApplicationFeatures, ApplicationToggles } from 'app/app-features.enum';

import { AppState } from '../app.state';

export const getAppState = createFeatureSelector<AppState>('app');
export const getThrobbers = createSelector(getAppState, (state: AppState) => state.throbbers);
export const getActiveDialogs = createSelector(getAppState, (state: AppState) => state.activeDialogs);
export const getActivePanel = createSelector(getAppState, (state: AppState) => state.activePanel);
export const getCurrentUser = createSelector(getAppState, (state: AppState) => state.currentUser);
export const getTenantUsers = createSelector(getAppState, (state: AppState) => state.tenantUsers);
export const getIsTenentUsersLoaded = createSelector(
    getAppState,
    (state: AppState) => state.isTenantUsersLoaded,
);
export const hasCurrentUserFeature = (feature: ApplicationFeatures | ApplicationToggles) =>
    createSelector(
        getAppState,
        (state: AppState) => state.currentUser?.features?.some((f) => f.name === feature && f.state) ?? false,
    );
export const isCurrentUserInternalUser = createSelector(
    getAppState,
    (state: AppState) => state.currentUser?.isInternalUser,
);

export const getError = createSelector(getAppState, (state: AppState) => state?.error);

export const selectShowPageLoader = createSelector(getAppState, (state: AppState) => state?.showPageLoader);
